/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function($) {

  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var Sage = {
    // All pages
    'common': {
      init: function() {
				var i;
				var pageLinks=document.getElementsByClassName('nav')[0].getElementsByTagName('li');

				for(i=0;i<pageLinks.length;i++){
					pageLink=pageLinks[i].getElementsByTagName('a');
					pageLink[0].addEventListener('click',linkClick,false);
				}

				var scrollToTopIcons=document.getElementsByClassName('scroll-to-top');
				for(i=0;i<scrollToTopIcons.length;i++){
					scrollToTopIcons[i].addEventListener('click',scrollToTop,false);
				}

				initNivoLightBox();



				var _gaq = [['_setAccount', 'UA-63967443-1'], ['_trackPageview']];
				(function(d, t) {
						var g = d.createElement(t),
						s = d.getElementsByTagName(t)[0];
						g.src = 'https://ssl.google-analytics.com/ga.js';
						s.parentNode.insertBefore(g, s);
						}(document, 'script'));



      },
      finalize: function() {
				$(headerImage).load(function(){
					setHeaderImageHeight();
					scrollToPage();
				});
			}
    },
    // Home page
    'home': {
      init: function() {
        // JavaScript to be fired on the home page
      },
      finalize: function() {
        // JavaScript to be fired on the home page, after the init JS
      }
    },
    // About us page, note the change from about-us to about_us.
    'about_us': {
      init: function() {
        // JavaScript to be fired on the about us page
      }
    }
  };

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function(func, funcname, args) {
      var fire;
      var namespace = Sage;
      funcname = (funcname === undefined) ? 'init' : funcname;
      fire = func !== '';
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === 'function';

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function() {
      // Fire common init JS
      UTIL.fire('common');

      // Fire page-specific init JS, and then finalize JS
      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, 'finalize');
      });

      // Fire common finalize JS
      UTIL.fire('common', 'finalize');
    }
  };

  // Load Events
  $(document).ready(UTIL.loadEvents);


	/* -- Global header image variable -- */

	var headerImage = new Image();
	headerImage.src=$('#header-background-container').css('background-image').replace(/^url\(["']?/, '').replace(/["']?\)$/, '');


	function linkClick(event){

		event.preventDefault();
		var destination=this.getAttribute('href').replace(window.location.protocol + "//" + window.location.host,'').replace(/\//g,'');

		if(destination===''){
			destination=$('.fast-lane-container:first .fast-lane-content h1').attr('id');
		}

		scrollTo(destination);

	}

	function scrollToPage(){

		if(window.location.pathname !== '/' && window.location.pathname !== ''){
			var destination=window.location.pathname.replace(/\//g,'');
			scrollTo(destination);
		}
	}

	function scrollTo(destination){

		if ($('#'+destination).length > 0) {
			$('html, body').animate({
				scrollTop:$('#'+destination).offset().top
			}, 500);
		}
	}


	function initNivoLightBox(){
		$('.lightbox').nivoLightbox({
			effect: 'fade',                             // The effect to use when showing the lightbox
			theme: 'dark',                          		// The lightbox theme to use
			keyboardNav: true,                          // Enable/Disable keyboard navigation (left/right/escape)
			clickOverlayToClose: true,                  // If false clicking the "close" button will be the only way to close the lightbox
			onInit: function(){},                       // Callback when lightbox has loaded
			beforeShowLightbox: function(){},           // Callback before the lightbox is shown
			afterShowLightbox: function(lightbox){},    // Callback after the lightbox is shown
			beforeHideLightbox: function(){},           // Callback before the lightbox is hidden
			afterHideLightbox: function(){},            // Callback after the lightbox is hidden
			onPrev: function(element){},                // Callback when the lightbox gallery goes to previous item
			onNext: function(element){},                // Callback when the lightbox gallery goes to next item
			errorMessage: 'The requested content cannot be loaded. Please try again later.' // Error message when content can't be loaded
		});

	}


	$(window).resize(function(){
		setHeaderImageHeight();
	});


	function setHeaderImageHeight(){
		var windowWidth=$( window ).width();
		$('#header-background-container').css('height', (windowWidth/headerImage.width) * headerImage.height + 'px');
	}

	function scrollToTop(){
		$('html, body').animate({
			scrollTop:0
		}, 500);
	}



	$(window).scroll(function() {
		$('.scroll-to-top').css('opacity','0');

		clearTimeout($.data(this, 'scrollTimer'));

		$.data(this, 'scrollTimer', setTimeout(function() {
			$('.scroll-to-top').css('opacity','0.7');
		}, 3000));
	});



})(jQuery); // Fully reference jQuery after this point.
